import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';



import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + '/home';
@Injectable()

@Injectable()
export class HomeService {

  constructor(private http: HttpClient, private _http: Http,
    private authservce: AuthService) { }

  getContent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }

  getDriverHome(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/driverhome';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }


  public uploadImage(formData: any) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let _url: string = API_URL + '/upload';
    return this._http.post(_url, formData,  { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));;
  }


  public uploadDriver(formData: any) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let _url: string = API_URL + '/uploadDriver';
    return this._http.post(_url, formData,  { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));
  }

  updatePage(formData: any, id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let _url: string = API_URL + '/update/' + id;
    return this._http.post(_url, formData,  { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));
  }


  updatePageImage(formData: any, id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let _url: string = API_URL + '/updateImage/' + id;
    return this._http.post(_url, formData,  { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));
  }

  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return Observable.throw(error || 'Some Error on Server Occured');

  }
  editPage(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }


  deletePage(id) {
    const uri = API_URL + '/delete/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .get(uri,  { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }


}
