
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + '/faqroutes';
@Injectable()
export class FaqService {

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }

  getContent(type): Observable<any> {
    console.log(type);
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };

    let apiUrl;

    if(type == 'user')
      apiUrl = environment.apiUrl + '/faqroutes/viewUser';
    else
      apiUrl = API_URL;

    return this
      .http
      .get(apiUrl, options).pipe(
        map(res => {
          return res;
        }));
  }

  add(page) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/add';
    const obj = {
      title: page.title,
      content: page.content,
      status: page.status,
      type: page.type

    };
    return this.https.post(uri, obj, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  delete(id) {
    const uri = API_URL + '/delete/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());

    return this
      .https
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  edit(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  editadminfaq(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/editfaq/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }


  update(page, id) {
    const uri = API_URL + '/update/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const obj = {
      title: page.title,
      content: page.content,
      status: page.status
    };
    return this
      .https
      .post(uri, obj, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }


  getCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/faqCount';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

}
