import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from 'app/service/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    user: any;
    userId: any;
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit() {
        this.authService.getUserinfo().subscribe(res => {
            this.user = res.user;
            this.userId = this.user[0]._id;
        });
    }

    onLogoutClick() {
        if (confirm('Are you sure you want to log out?')) {
            this.authService.logout();
            this.router.navigate(['/admin']);
            return false;
        }
    }
}
