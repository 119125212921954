import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { BasicAuthInterceptor } from 'app/provider/basic.interceptor'

import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxDropdownMenuSearchModule } from 'ngx-dropdown-menu-search';
import { HighchartsChartModule } from 'highcharts-angular';

import * as $ from 'jquery';

/* JWT AUTHENTICATION */
import { JwtModule } from '@auth0/angular-jwt';

export function tokenGetter() {
    return localStorage.getItem('authtoken');
}

/* Services */
import { AuthService } from './service/auth.service';
import { UserService } from './service/user.service';
import { DriverService } from './service/driver.service';
import { VehicleService } from './service/vehicle.service';
import { RidesService } from './service/rides.service';
import { PageService } from './service/page.service';
import { FaqService } from './service/faq.service';
import { HomeService } from './service/home.service';
import { BannerService } from './service/banner.service';
import { SettlementService } from './service/settlement.service';
import { AdminguardGuard } from './guards/adminguard.guard';
import { CallRequestService } from './service/call-request.service';
import { NotificationService } from './service/notification.service';
import { PushNotificationsModule } from 'ng-push'
import { PushNotificationsService } from 'ng-push';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { ErrorInterceptor } from './provider/error-interceptor'


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        HttpModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            progressBar:true
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['']
            }
        }),
        NgbModule.forRoot(),
        PushNotificationsModule,
        NgxDropdownMenuSearchModule,
        NgMultiSelectDropDownModule.forRoot(),
        HighchartsChartModule
    ],
    providers: [AdminguardGuard, AuthService, PageService, FaqService,
        HomeService, BannerService, UserService, DriverService, VehicleService, RidesService, SettlementService, CallRequestService,PushNotificationsService, NotificationService,
        // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
