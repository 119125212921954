
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + '/rides';

@Injectable()
export class RidesService {
  corporateId;

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { 
      this.corporateId = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).corporate ? JSON.parse(localStorage.getItem('user')).corporate._id : null;
    }

    // getUserListByCorporateAdmin(pageNumber,userData){
    //   const url = environment.apiUrl+ '/user/list/corporateAdmin?page='+ pageNumber +'&limit=25';
  
    //   const headers = new Headers();
    //   headers.append('Authorization', this.authservce.getToken());
  
    //   return this._http.post(url,userData,{headers : headers}).pipe(
    //     catchError(this._errorHandler),
    //     map(res => {
    //       return res.json();
    //     }));
    // }

    // corporate admin ride history page API
    // https://dev.lwayonline.com:311/api/ridehistoryPage?page=1&limit=10
    getRideHistory(pageNumber, rideData){
      const url = environment.apiUrl + '/api/ridehistoryPage?page=' + pageNumber + '&limit=10';

      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());


      return this.https.post(url,rideData,{headers:headers}).pipe(
        map(res => {
          return res.json();
        }));

    }
    // --end

  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }

  getRides(pageNumber, filterData): Observable<any> {
    console.log(filterData);
    const headers = new Headers();
    const uri = API_URL + '/list?page='+ pageNumber +'&limit=10'
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(uri, filterData, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  cancelRide(body): Observable<any> {
    console.log(body);
    const headers = new Headers();
    const uri = environment.apiUrl + '/api/cancelCorporateRide';
    console.log(uri);
    headers.append('Authorization', this.authservce.getToken());
    headers.append('CorporateId', this.corporateId);

    return this.https.post(uri, body, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  deactiveVehicle(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(API_URL + '/deactive/' + id, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  activeVehicle(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(API_URL + '/active/' + id, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  completeRideDetails(params) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(environment.apiUrl + '/api/completeridedetails', params, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }


  getInfo(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/info/' + id, options).pipe(
        map(res => {
          return res;
        }));
  }



  getDriverRide(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/getDriverRide/' + id, options).pipe(
        map(res => {
          return res;
        }));
  }


  getDriverRideCount(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/getDriverRideCount/' + id, options).pipe(
        map(res => {
          return res;
        }));
  }


  getcount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .get(API_URL + '/totalCount/', { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }


  getcurrentMonthride() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .get(API_URL + '/currentMonth/', { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }


  getLatestride(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/latestRide/', options).pipe(
        map(res => {
          return res;
        }));
  }


  getCancelCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/cancel/', options).pipe(
        map(res => {
          return res;
        }));
  }



  getCompletedCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/completed/', options).pipe(
        map(res => {
          return res;
        }));
  }


  getOnrideCount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .get(API_URL + '/onride/', { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  getOnthewayCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/ontheway/', options).pipe(
        map(res => {
          return res;
        }));
  }


  getScheduledCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/scheduled/', options).pipe(
        map(res => {
          return res;
        }));
  }


  getAcceptedCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/accepted/', options).pipe(
        map(res => {
          return res;
        }));
  }


  getNotAcceptCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/ridenotaccepted/', options).pipe(
        map(res => {
          return res;
        }));
  }

  gettodayCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/currentRecords/', options).pipe(
        map(res => {
          return res;
        }));
  }

  updateMeterReading(newMeterReadings){
    const headers = new Headers();
    const uri = API_URL + '/updateMeterReading';
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(uri, newMeterReadings, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }
    //ride track api
    rideTrack(id) {
      return this
        .http
        .post(API_URL + '/track/emergency/' + id,{}).pipe(
          map( res => {
            return res;
          }));
    }
  


}

