import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  loaded = false;
  thumbnail: any;
  @Input() imageUrl: string;
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    console.log(this.imageUrl);
    let objectURL = this.imageUrl;
    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.loaded = true;
  }

}
