import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FrontheaderComponent } from './frontheader/frontheader.component';
import { FrontfooterComponent } from './frontfooter/frontfooter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InnerheaderComponent } from './innerheader/innerheader.component';
import { InnerfooterComponent } from './innerfooter/innerfooter.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FrontheaderComponent,
        FrontfooterComponent,
        InnerheaderComponent,
        InnerfooterComponent,
        ToggleFullscreenDirective,
        ImageViewerComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule

    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        FrontheaderComponent,
        FrontfooterComponent,
        InnerheaderComponent,
        InnerfooterComponent,
        ImageViewerComponent,
    ],
})
export class SharedModule { }
