
import { Observable } from 'rxjs/Rx';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + '/banner';
@Injectable()
export class BannerService {

  constructor(private http: HttpClient, private _http: Http,
    private authservce: AuthService) { }

  getContent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }

  public addBanner(formData: any) {
    let _url: string = API_URL + '/add';
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this._http.post(_url, formData, { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));
  }

  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return Observable.throw(error || 'Some Error on Server Occured');

  }

  editPage(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  updatePage(formData: any, id) {
    const uri = API_URL + '/update/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .post(uri, formData, { headers: headers }).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
  }


  updatePageImage(formData: any, id) {
    let _url: string = API_URL + '/updateImage/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this._http.post(_url, formData, { headers: headers }).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res.json();
      }));
  }

  //home page

  getUserBanner(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/user';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  getDriverBanner(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/driver';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }


  deletePage(id) {
    const uri = API_URL + '/delete/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }


  getCount() : Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/count';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  getUserCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/userCount';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  getDriverCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/driverCount';
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }
}
