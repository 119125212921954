
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const API_URL = environment.apiUrl + '/vehicleRoutes';

@Injectable()
export class VehicleService {

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }

  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }

  getPage1(page,body): Observable<any> {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .post(API_URL + '/list?page='+ page +'&limit=25', body, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  deactiveVehicle(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(API_URL + '/deactive/' + id, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  activeVehicle(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this.https.post(API_URL + '/active/' + id, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }


  getInfo(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/info/' + id, options).pipe(
        map(res => {
          return res;
        }));
  }


  getcount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .get(API_URL + '/totalCount/', { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  getCategory(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/category', options).pipe(
        map(res => {
          return res;
        }));
  }

  edit(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL + '/edit/' + id, options).pipe(
        map(res => {
          return res;
        }));
  }

  update(page, id) {
    console.log(page);
    const obj = {
      category: page.category,
      status: page.status,
      user_id: page.user_id,
      inspectionon: page.datepicker,
      secondary_categories: page.secondary_categories,
      long_ride_body_type: page.long_ride_body_type
    };
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .post(API_URL + '/update/' + id, obj, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  update1(page, id, user_id) {
    console.log(page);
    const obj = {
      body_type : page.bodytype,
      amenities : page.aminities,
      user_id : user_id
    };
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      .https
      .post(API_URL + '/update/' + id, obj, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  downloadcsv(filterData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/download/list';
    return this.https.post(uri, filterData, { headers: headers }).pipe(
      map(res => {
        console.log(res);
        return res.json();
      }));
  }


}
