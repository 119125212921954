
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl+'/page';
@Injectable()
export class PageService {

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }

  addPage(page) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const obj = {
      title: page.title,
      content: page.content,
      pagetype:page.pagetype,
      status:1,
      type:'driver',
    };
   return  this.https.post(API_URL+'/add', obj, { headers: headers }).pipe(
   map(res => {
    return res.json();
  }));
  }



  addUser(page) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const obj = {
      title: page.title,
      content: page.content,
      pagetype:page.pagetype,
      status:1,
      type:'user',
    };
   return  this.https.post(API_URL+'/addUser', obj, { headers: headers }).pipe(
   map(res => {
    return res.json();
  }));
  }


  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL,options).pipe(
            map(res => {
              return res;
            }));
  }



  getUserPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/viewUser', options).pipe(
            map(res => {
              return res;
            }));
  }


  getTerms(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/terms', options).pipe(
            map(res => {
              return res;
            }));
  }
  getPrivacy(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/privacy', options).pipe(
            map(res => {
              return res;
            }));
  }

  getUserHelpcontent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/userHelp', options).pipe(
            map(res => {
              return res;
            }));
  }

  getDriverHelpcontent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/driverHelp', options).pipe(
            map(res => {
              return res;
            }));
  }

  getDriverTerms(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/termsDriver', options).pipe(
            map(res => {
              return res;
            }));
  }
  getDriverPrivacy(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/privacyDriver', options).pipe(
            map(res => {
              return res;
            }));
  }


  getDriverInspection(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/inspectionDriver', options).pipe(
            map(res => {
              return res;
            }));
  }
  getDriverSecurity(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/securityDriver', options).pipe(
            map(res => {
              return res;
            }));
  }


  deletePage(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
        return this
            .https
            .get(API_URL+'/delete/'+ id, { headers: headers }).pipe(
            map(res => {
              return res.json();
            }));
  }

  editPage(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/edit/'+ id, options).pipe(
            map(res => {
              return res;
            }));
  }


  editDriverPage(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/editdriverpage/'+ id, options).pipe(
            map(res => {
              return res;
            }));
  }

  // updatePage(page, id): Observable<any> {
  //   const options = {
  //     params: {},
  //     headers: this.authservce.setHeaders()
  //   };s
  //   const obj = {
  //     title: page.title,
  //     content: page.content
  //   };
  //   this
  //     .http
  //     .post(API_URL+'/update/'+ id, obj, options)
  //     .subscribe(res => );
  // }
  updatePage(page, id){
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const obj = {}
        return this
            .https
            .post(API_URL+'/update/'+ id, page, { headers: headers }).pipe(
            map(res => {
              return res.json();
            }));
  }

  getSocial(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/social/', options).pipe(
            map(res => {
              return res;
            }));
  }



  getdriverHelpCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL+'/driverHelpCount';
    return this
            .http
            .get(uri, options).pipe(
            map(res => {
              return res;
            }));
  }

  getuserHelpCount(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL+'/userHelpCount';
    return this
            .http
            .get(uri, options).pipe(
            map(res => {
              return res;
            }));
  }

  getDriverHelppage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/getDriverHelppage', options).pipe(
            map(res => {
              return res;
            }));
  }
}
