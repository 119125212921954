import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/service/auth.service';
import { PageService } from 'app/service/page.service';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-innerheader',
  templateUrl: './innerheader.component.html',
  styleUrls: ['./innerheader.component.scss']
})
export class InnerheaderComponent implements OnInit {

  user: any;
  count: any;
  pages: any;
  url: any;
  constructor(private authService: AuthService, private service: PageService) { }

  ngOnInit() {
    this.url = environment.apiUrl;
    this.authService.getUserinfo().subscribe(res => {
      this.user = res.user;

    });

    this.service.getdriverHelpCount().subscribe(res => {
      this.count = res;
    });
    this.service.getDriverHelppage().subscribe(res => {
      this.pages = res;
    });
  }

  hyphenateUrlParams(str: string) {
    return str = str.split(' ').join('-');
  }
}