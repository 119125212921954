import { Observable } from 'rxjs/Rx';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/* JWT AUTHENTICATION */
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../environments/environment';
import { ConfigSetting } from 'app/shared/interfaces/coupon-details.model';

const API_URL = environment.apiUrl + '/admin';
@Injectable()
export class AuthService {
  authToken: any;
  user: any;
  _url: string;
  menuItems: any;
  public configSetting: ConfigSetting = new ConfigSetting();

  constructor(private http: Http, private Httpclient: HttpClient, private jwtHelperService: JwtHelperService) {
    // this.isDev = true;  // Change to false before deployment
  }

  registerUser(user) {
    let headers = new Headers();
    return this.http.post(API_URL + '/register', user, { headers: headers }).pipe(
      map(res => res.json()));
  }

  authenticateUser(user) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(API_URL + '/authenticate', user, { headers: headers }).pipe(
      map(res => res.json()));
  }


  /* checking jwt token expiration */
  loggedIn() {
    const token: string = this.jwtHelperService.tokenGetter();
    if (!token) {
      return false
    }
    const tokenExpired: boolean = this.jwtHelperService.isTokenExpired(token);
    return !tokenExpired;
  }

  /* clear the userdata & logout user */
  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  /* store userdata after successful authentication */
  storeUserData(token, user) {
    localStorage.setItem('authtoken', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  /* get the stored token */
  loadToken() {
    const token = localStorage.getItem('authtoken');
    this.authToken = token;
  }

  getToken() {
    this.loadToken();
    return this.authToken
  }

  getProfile() {
    const headers = new Headers();
    this.loadToken();
    headers.append('Authorization', this.authToken);
    // headers.append('Content-Type', 'application/json');
    return this.http.get(API_URL + '/profile', { headers: headers }).pipe(
      map(res => res.json()));
  }


  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return Observable.throw(error || 'Some Error on Server Occured');

  }


  updateLogo(formData: any) {
    const uri = API_URL + '/logo/';
    return this
      .http
      .post(uri, formData).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
  }


  updateFavicon(formData: any) {
    const uri = API_URL + '/favicon/';
    return this
      .http
      .post(uri, formData).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
  }


  updateEmail(user, id) {
    return this
      .http
      .post(API_URL + '/update/' + id, user).pipe(
        map(res => res.json()));
  }


  updateSetting(user, id, symbol) {
    const obj = {
      username: user.username,
      email: user.email,
      footer: user.footer,
      apptitle: user.apptitle,
      appcontent: user.appcontent,
      phone: user.phone,
      distancePerCab: user.distancePerCab,
      tax: user.tax,
      maxDistance: user.maxDistance,
      currencyCode: user.currencyCode,
      siteName: user.siteName,
      currencySymbol: symbol,
      emergencyContact: user.emergencyContact,
      FCMUserKey: user.FCMUserKey,
      FCMDriverKey: user.FCMDriverKey,
      googleMapKey: user.googleMapKey,
      inspectionon: user.inspectionon,
      helppagesheader: user.helppagesheader,
      maxdisperride: user.maxdisperride,
    };
    return this
      .http
      .post(API_URL + '/setting/' + id, obj).pipe(
        map(res => res.json()));
  }


  updateSMTP(user, id) {
    return this
      .http
      .post(API_URL + '/emailSetting/' + id, user).pipe(
        map(res => res.json()));
  }

  getUserinfo() {
    const headers = new Headers();
    headers.append('Authorization', this.getToken());
    return this.http.get(API_URL + '/getProfile', { headers: headers }).pipe(
      map(res => res.json()));
  }
  sendMail(form) {
    return this
      .http
      .post(API_URL + '/sendMail/', form).pipe(
        map(res => {
          return res.json();
        }));
  }



  ChangePassword(profile, id) {
    return this
      .http
      .post(API_URL + '/changePassword/' + id, profile).pipe(
        map(res => {
          return res.json();
        }));
  }


  updateApp(user, id) {
    return this
      .http
      .post(API_URL + '/app/' + id, user).pipe(
        map(res => res.json()));
  }

  updatePayment(payment, id) {
    return this
      .http
      .post(API_URL + '/payment/' + id, payment).pipe(
        map(res => res.json()));
  }

  setConfig(configItems, edit_access,  view_access) {
    configItems.forEach(item => {
      if (edit_access.indexOf(item.path) > -1) {
        item.isEdit = true;
      } else {
        item.isEdit = false;
      }

      if (view_access.indexOf(item.path) > -1) {
        item.viewOnly = true;
      } else {
        item.viewOnly = false;
      }

      if (item.submenu.length > 0) {
        this.setConfig(item.submenu, edit_access,  view_access);
      }
    });

  }
  adminConfig(configItems) {
   configItems.forEach(item => {
        item.isEdit = true;
        item.viewOnly = true;
      if (item.submenu.length > 0) {
        this.adminConfig(item.submenu);
      }
    });
  }

   /**
   * This function sets header and returns
   * @returns HttpHeaders
   */
  public setHeaders(type = 'any'): HttpHeaders {
    let headersConfig = {};
    this.loadToken();
   if (type === 'upload' || type === 'formData') {
    } else {
      headersConfig = {
        'Content-Type': 'application/json',
        'Authorization' : this.authToken
      };
    }
    return new HttpHeaders(headersConfig);
  }


}
