import { map , catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { containsTree } from '@angular/router/src/url_tree';
// import { CouponDetails, CouponsParams } from 'app/shared/interfaces/coupon-details.model';
const API_URL = environment.apiUrl;

@Injectable()
export class CallRequestService {


  // public couponDetails: CouponDetails = new CouponDetails();
  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }

    
    getPendingListPage(pageNumber = ''): Observable<any> {
      const uri = API_URL + '/call-request/pending?page=' + pageNumber + '&limit=10';
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      let body ={};
      return this.https.post(uri, body,  { headers: headers } ).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
    }

    getAllList(pageNumber) : Observable<any> {
      const uri = API_URL + '/call-request/list?page=' + pageNumber + '&limit=10';
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      let body ={};
      return this.https.post(uri, body,  { headers: headers } ).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
    }

    getCallDetailById(id): Observable<any>{
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
      const uri = API_URL + '/call-request/edit/' + id;
      // const uri = API_URL
      return this
              .http
              .get(uri, options).pipe(
              map(res => {
                return res;
              }));
    }
    updateCallRequestDetails(form,id) : Observable<any>{
      console.log(form)
      const uri = API_URL + '/call-request/update/'+id;
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      let body =form;
      return this.https.post(uri, body,  { headers: headers } ).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
      
    }
    private _errorHandler(error: Response) {
      console.error('Error Occured: ' + error);
      return Observable.throw(error || 'Some Error on Server Occured');
    }
    postAcceptCallRequest(id) : Observable<any>{
      const uri = API_URL + '/call-request/accept/'+id;
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      let body ={};
      return this.https.post(uri, body,  { headers: headers } ).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
    }  

    activeUser(id) {
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      const uri = API_URL + '/active/' + id;
      return  this.https.post(uri, id, { headers: headers }).pipe(
     map(res => {
      return res.json();
    }));
    }

    unattended(page) {
      const headers = new Headers();
      let body = {};
      headers.append('Authorization', this.authservce.getToken());
      const uri = API_URL + '/call-request/unAttended?page=' + page + '&limit=10';
      return  this.https.post(uri, body, { headers: headers }).pipe(
     map(res => {
      return res.json();
    }));
    }

}
