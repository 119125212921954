import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/service/auth.service';
import { environment } from '../../../environments/environment';

declare var $: any;
@Component({
  // moduleId: module.id,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  userinfo: any;
  site_title: string;
  sitelogo: string;
  edit_access = [];
  view_access = [];

  constructor(private router: Router,
    private route: ActivatedRoute, private authService: AuthService, ) {
      const brandImg = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).corporate ? JSON.parse(localStorage.getItem('user')).corporate.brandImage : null;

      let imageUrl = environment.imageUrl;
      let modifiedImgUrl = imageUrl.substring(0, imageUrl.lastIndexOf("src"));

      if (brandImg) {
        this.sitelogo = modifiedImgUrl + brandImg;
      } else {
        this.sitelogo = '../assets/uploads/logo-resized.png'
      }
  }


  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log(this.menuItems);

    //  displaying sidebar for corporate admin
    this.authService.adminConfig(this.menuItems);
    

    // for admin
    // this.authService.getProfile().subscribe(profile => {
    //   console.log("user profile--",profile);
      
    //   console.log("inside get sidebar..");
      
    //   this.userinfo = profile.user;
    //   this.site_title = this.userinfo.siteName;
    //   if (profile.user.root) {
    //     this.authService.adminConfig(this.menuItems);
    //   } else {
    //     this.edit_access = profile.user.role.edit_access;
    //     console.log(this.edit_access );
        
    //     this.view_access = profile.user.role.view_access;

    //     console.log(this.edit_access );
    //     this.reConfig(this.menuItems);
    //   }
    // });
  }

  onLogoutClick() {
    if (confirm('Are you sure you want to log out?')) {
      this.authService.logout();
      this.router.navigate(['/admin']);
      return false;
    }
  }
  reConfig(configItems) {
    configItems.forEach(item => {
      if (this.edit_access.indexOf(item.path) > -1) {
        item.editOnly = true;
      } else {
        item.editOnly = false;
      }

      if (this.view_access.indexOf(item.path) > -1) {
        item.viewOnly = true;
      } else {
        item.viewOnly = false;
      }

      if (item.submenu.length > 0) {
        this.reConfig(item.submenu);
        let hideParent = true;
        item.submenu.forEach(menuItem => {
          if (menuItem.viewOnly == true) {
            hideParent = false;
          }
        });

        if (hideParent === true) {
          item.viewOnly = false;
        } else {
          item.viewOnly = true;
        }
      }
    });
  }
  adminConfig(configItems) {
    configItems.forEach(item => {
      item.editOnly = true;
      item.viewOnly = true;
      if (item.submenu.length > 0) {
        this.adminConfig(item.submenu);
      }
    });
  }
}
