import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from 'app/service/auth.service';
@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    user: any;
    constructor(private authService: AuthService) { }
    ngOnInit() {
        this.authService.getUserinfo().subscribe(res => {
            this.user = res.user;
            this.user[0]['footer' ] = 'Lway © 2023. All rights reserved.'
        });
    }
}
