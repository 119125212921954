import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/service/auth.service';
import { FaqService } from 'app/service/faq.service';
import { environment } from 'environments/environment.prod';
@Component({
  selector: 'app-frontheader',
  templateUrl: './frontheader.component.html',
  styleUrls: ['./frontheader.component.scss']
})
export class FrontheaderComponent implements OnInit {
  user: any;
  url: any;
  faqCount: any;
  constructor(private authService: AuthService, private faq: FaqService) { }

  ngOnInit() {
    this.url = environment.apiUrl;
    this.authService.getUserinfo().subscribe(res => {
      this.user = res.user;

    });

    this.faq.getCount().subscribe(res => {
      this.faqCount = res;
    });
  }

}
