
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const API_URL = environment.apiUrl+'/settlement';
@Injectable()
export class SettlementService {

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }


  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL, options).pipe(
            map(res => {
              return res;
            }));
  }

 add(driverID, totalFare, commissionAmt, tax, earn, countpage) {
  const headers = new Headers();
  headers.append('Authorization', this.authservce.getToken());
  const uri = API_URL + '/add';
  const obj = {
    driver_id: driverID,
    total_ridefare: totalFare,
    total_commissionAmt: commissionAmt,
    total_tax: tax,
    total_earning: earn,
    ride_count: countpage,
    payment_status: 1,
  };
 return  this.https.post(uri, obj, { headers: headers }).pipe(
 map(res => {
  return res.json();
}));
}


getcount() {
  const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
  return this
          .https
          .get(API_URL + '/totalCommission/', { headers: headers }).pipe(
          map(res => {
            return res.json();
                  }));
}




}
