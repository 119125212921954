
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const API_URL = environment.apiUrl+'/user';


@Injectable()
export class UserService {

  constructor(private http: HttpClient, private https: Http, private authservce: AuthService) { }

  // /**
  //  * This function sets header and returns
  //  * @returns HttpHeaders
  //  */
  // private setHeaders(type = 'any'): HttpHeaders {
  //   let headersConfig = {};
  //  if (type === 'upload' || type === 'formData') {
  //   } else {
  //     headersConfig = {
  //       'Content-Type': 'application/json',
  //       'Authorization' : this.authservce.getToken()
  //     };
  //   }
  //   return new HttpHeaders(headersConfig);
  // }

  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL, options).pipe(
            map(res => {
              return res;
            }));
  }

  getUser(pageNumber, filterData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = environment.apiUrl+'/user/list?page='+ pageNumber +'&limit=25';

    return  this.https.post(uri, filterData, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  deactiveUser(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());

   return  this.https.post(API_URL+'/deactive/'+id, id,  { headers: headers }).pipe(
   map(res => {
    return res.json();
  }));
  }

  activeUser(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
   return  this.https.post(API_URL+'/active/'+id, id, { headers: headers }).pipe(
   map(res => {
    return res.json();
  }));
  }

  resetWalletMoney(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(API_URL+'/resetWallet/'+id, {}, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  getInfo(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
   return this
            .http
            .get(API_URL+'/info/'+id, options).pipe(
            map(res => {
              return res;
            }));
  }

  getcount(){
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
            .https
            .get(API_URL+'/userCount/', { headers: headers } ).pipe(
            map(res => {
              return res.json();
                    }));
  }

    getLatestuser() : Observable<any> {
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
    return this
            .http
            .get(API_URL+'/latestUser/', options).pipe(
            map(res => {
              return res;
            }));
  }

  getiosuser() : Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/iosUser/', options).pipe(
            map(res => {
              return res;
            }));
  }

  getandoriduser(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
            .http
            .get(API_URL+'/andoridUser/', options).pipe(
            map(res => {
              return res;
            }));
  }

  sendNotification(Form) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());

   return  this.https.post(API_URL+'/pushNotify', Form, { headers: headers }).pipe(
   map(res => {
    return res.json();
  }));
  }

  sendMessage(formData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(environment.apiUrl+'/api/notifySingleUser', formData, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }
}
