
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Body } from '@angular/http/src/body';

const API_URL = environment.apiUrl + '/driverRoutes';
@Injectable()
export class DriverService {
  corporateId;

  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { 
      this.corporateId = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).corporate ? JSON.parse(localStorage.getItem('user')).corporate._id : null;
    }



  getPage(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }

  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return Observable.throw(error || 'Some Error on Server Occured');

  }

  driverList(pageNumber, filterData): Observable<any> {
    let body;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/list?page='+ pageNumber +'&limit=25';
    console.log(uri);
    return this.https.post(uri, filterData,{ headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  getOnlineOutstationDriver(form) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    headers.append('CorporateId', this.corporateId);

    const uri = environment.apiUrl + '/vehicleRoutes/list';
    console.log(uri);
    return this.https.post(uri, form,{ headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  deactiveUser(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/deactive/' + id;
    return this.https.post(uri, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  activeUser(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/active/' + id;
    return this.https.post(uri, id, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  getInfo(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/info/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  getcount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/driverCount/';
    return this
      .https
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  getonlineDrivercount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/onlineDriver/';
    return this
      .https
      .get(uri, { headers: headers }).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
  }

  getactiveDrivercount() {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/activeDriver/';
    return this
      .https
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  sendMessage(formData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(environment.apiUrl+'/driverapi/notifySingleDriver', formData, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  resetCancellationCharge(driver_id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(API_URL+'/resetCancellationCharges/'+driver_id, {}, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  deleteDriver(driver_id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(API_URL +'/delete/' + driver_id, {}, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  updateDeposit(driver_id, body) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(API_URL +'/updateDeposit/' + driver_id, body, { headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

  downloadSettlementCsv(filterData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/download/pendingPayments';
    return this.https.post(uri, filterData, { headers: headers }).pipe(
      map(res => {
        console.log(res);
        return res.json();
      }));
  }

  driverRouteUpdate(body, driver_id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + `/update/${driver_id}`;
    return this.https.post(uri, body, { headers: headers }).pipe(
      map(res => {
        console.log(res);
        return res.json();
      }));
  }

  resetWalletMoney(id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return  this.https.post(environment.apiUrl+'/driverapi/resetWallet/'+id,{},{ headers: headers }).pipe(
    map(res => {
      return res.json();
    }));
  }

}
